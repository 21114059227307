.carousel-wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.carousel {
  width: 100%;
}

.carousel .slide img {
  width: 100%;
  height: auto; /* Allow height to adjust naturally */
  object-fit: contain; /* Change to 'contain' to fully show the image */
}

@media (max-width: 768px) {
  .carousel .control-arrow {
    display: none;
  }
}

.carousel .control-dots .dot {
  background-color: #666;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
}

.carousel .control-dots .dot.selected {
  background-color: #000;
}

.carousel .control-arrow {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  padding: 10px;
  z-index: 1;
  color: white;
}

.carousel .control-prev.control-arrow {
  left: 20px;
}

.carousel .control-next.control-arrow {
  right: 20px;
}
