.App{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction:column ;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f0f0f0;
}

body{
  padding: 0;
  margin: 0;
}
.carousel {
  margin: 0 !important;
  padding: 0 !important;
}